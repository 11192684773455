import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import DownloadIcon from '@mui/icons-material/Download';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
  } from '@mui/material';

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import { ColorModeContext, tokens } from "../theme";
import TokenExpiration from '../Auth/TokenExpiration';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Expiration from '../Components/Expiration';

// SUBMIT NOTIFICATIONS
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// SUBMIT NOTIFICATIONS

const api = axios.create({baseURL: global_link});

function Recordings() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const currentDate = new Date();
    const [currentDay, setcurrentDay] = useState(currentDate.getDate());
    const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth() + 1);
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
    const [activeAudioKey, setActiveAudioKey] = useState(null);
    const [audioElement, setAudioElement] = useState(null);
    const [formData, setFormData] = useState({});

    const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10
    });

    const fetchDataRecord = async () => {
        try {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          const response = await api.get('/get_recordings/', {
            params: {
              year: currentYear,
              month: currentMonth,
              day: currentDay,
              search: searchText,
              page: controller.page,
              size: controller.rowsPerPage,
            },
            headers,
            withCredentials: true,
          });
          const data = response.data;

          if (data.totalResults>0) {
          setOpenSuccess(true);}else{
            setOpenError(true);  
          }
          setFilteredData(data.results);
          setTotalItems(data.totalResults);
        } catch (error) {
          console.error(error);
          setOpenError(true);  
        }
      };
      
      useEffect(() => {
        fetchDataRecord();
      }, [searchText, controller]);


const handleChangePage = (event, newPage) => {
  setController({
    ...controller,
    page: newPage
  });
};

const handleChangeRowsPerPage = (event) => {
  setController({
    ...controller,
    rowsPerPage: parseInt(event.target.value, 10),
    page: 0
  });
};

const handleInputChange = (event) => {
    const { name, value } = event.target;
    const [year, month, day] = value.split('-');
    setcurrentDay(day);
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const handleSumitClick = () => {
    // Call fetchDataRecord when the button is clicked
    fetchDataRecord();
  };


  const handleDateSubmit = (event) => {
    event.preventDefault();
    handleSumitClick();
  };

  const getAudioDownload = async (path, name) => {
    try {
        setOpenWarning(true);
      const accessToken = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
  
      const response = await api.get(`/getfilerecord/`, {
        params: {
          path: path,
          name: name,
        },
        headers,
        responseType: 'arraybuffer',
        withCredentials: true,
      });
  
      if (response.status === 200) {
        setOpenWarning(false);
        setOpenSuccess(true);
        const fileContent = response.data;
        // Create a Blob from the file content
        const blob = new Blob([fileContent], { type: 'application/octet-stream' });
  
        // Create a temporary anchor element and set its attributes
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${name}.wav`; // Set the desired file name
  
        // Trigger a click on the anchor element to initiate the download
        downloadLink.click();
  
        // Cleanup: remove the temporary anchor element
        URL.revokeObjectURL(downloadLink.href);
      } else {
        console.error('Failed to download file:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error in getFileDownload:', error);
    }
  };
  

  const handlePlayPause = async (row) => {
    const audioKey = row.xml_cdr_uuid;
    const audioUrl = `/getfilerecord/?path=${row.record_path}&name=${audioKey}`;
    setOpenWarning(true);
    if (activeAudioKey === audioKey) {
      // Pause the audio
      audioElement.pause();
      setActiveAudioKey(null);
      setOpenWarning(false);
    } else {
      try {
        const accessToken = localStorage.getItem('access_token');
        const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };
  
        const response = await api.get(`/getfilerecord/`, {
          params: {
            path: row.record_path,
            name: audioKey,
          },
          headers,
          responseType: 'arraybuffer',
          withCredentials: true,
        });
  
        if (response.status === 200) {
          setOpenWarning(false);
          const fileContent = response.data;
          // Create an <audio> element and set its source to the created URL
          const newAudioElement = new Audio();
          setAudioElement(newAudioElement);
  
          // Listen for the 'ended' event
          newAudioElement.addEventListener('ended', () => {
            setActiveAudioKey(null);
            setOpenWarning(false);
          });
  
          // Play the downloaded file
          const blob = new Blob([fileContent], { type: 'application/octet-stream' });
          const url = URL.createObjectURL(blob);
          newAudioElement.src = url;
          newAudioElement.play();
          setActiveAudioKey(audioKey);
        } else {
          console.error('Failed to download file:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error in getFileAudioPlay:', error);
      }
    }
  };



function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = hours.toString();
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  
    return `${formattedHours}.${formattedMinutes}.${formattedSeconds}`;
  }
  
  


  // SUBMIT NOTIFICATIONS
const [openSuccess, setOpenSuccess] = useState(false);
const [openError, setOpenError] = useState(false);
const [openWarning, setOpenWarning] = useState(false);

const handleSuccessClose = (event, reason) => {
  if (reason === 'clickaway') {return;}setOpenSuccess(false);}; 

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setOpenError(false);}; 

const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setOpenWarning(false);}; 

  const [state, setState] = useState({openServer: false,vertical: 'top',horizontal: 'center',});
  const { vertical, horizontal} = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    setOpenSuccess(false);
    setOpenError(false);
    setOpenWarning(false);
  },[]);
   // SUBMIT NOTIFICATIONS

   function addHoursToTimestamp(timestamp, hoursToAdd) {
    const originalDate = new Date(timestamp);
    originalDate.setHours(originalDate.getHours() + hoursToAdd);
  
    // Format the new date as a string
    const newTimestamp = originalDate.toISOString().slice(0, 16).replace('T', ' ');
  
    return newTimestamp;
  }

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2} sx={{marginBottom:'20px'}}>Recordings</Typography>

    <Box
        m="40px 0 0 0"
        height="75vh"
        mt="-15px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            // color: colors.greenAccent[300],
            color: colors.primary[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >

<div>


<form onSubmit={handleDateSubmit}>
    <Grid container spacing={2}  p={1} >
        <Grid item xs={2}>
 
    <TextField
          name="date"
          type="date"
          onChange={handleInputChange}
          fullWidth
          sx={{marginTop:"10px"}}
          variant="outlined"
          required
        />
    </Grid>


    <Grid item xs={2}>

        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'15px',marginTop:'10px!important',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
    </Grid>

        </form>
        <br/>
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Unique</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Date</TableCell> 
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.xml_cdr_uuid}</TableCell>
                  <TableCell>
                        {row.json.variables.caller_id_number &&
                        row.json.variables.caller_id_number.length > 5
                        ? row.json.variables.caller_id_number.slice(3)
                        : row.json.variables.caller_id_number}
                  </TableCell>
                  <TableCell>{row.json.variables.origination_callee_id_name}</TableCell>
                  <TableCell>{secondsToHMS(row.duration)}</TableCell>
                  <TableCell>{addHoursToTimestamp(row.end_stamp.slice(0, -8).replace('T', ' '), 6)}</TableCell>
                  <TableCell>{row.direction}</TableCell>
                  <TableCell>

                  <Button
    variant="contained"
    sx={{ backgroundColor: colors.blueAccent[600] }}
    onClick={() => handlePlayPause(row)}
  >
    {activeAudioKey === row.xml_cdr_uuid ? (
      <PauseCircleFilledIcon />
    ) : (
      <PlayCircleFilledWhiteIcon />
    )}
  </Button>{' '}
   | {' '} <Button variant="contained" sx={{backgroundColor: colors.greenAccent[500]}} onClick={ () => getAudioDownload (row.record_path, row.xml_cdr_uuid)}><DownloadIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>

    <Stack spacing={2} sx={{ width: '100%'}}>
  <Snackbar open={openError} autoHideDuration={4000} onClose={handleErrorClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
Error Occured / No Data!!!
  </Alert>
  </Snackbar>

  <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
  Success 
  </Alert>
  </Snackbar>

  <Snackbar open={openWarning} autoHideDuration={null} onClose={handleWarningClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert severity="warning" sx={{ width: '100%' }}>
  <CircularProgress size={25} /> Please Wait ...
  </Alert>
  </Snackbar>


  </Stack>

      </Box>

  </Box>
  );
}

export default Recordings;